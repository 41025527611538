import { createSlice } from '@reduxjs/toolkit'

interface AlertState {
  alertType: string,
  message: string,
  open:boolean,
}

const initialState = { alertType:  "", message: "", open:false} as AlertState

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert(state, {payload}) {
      state.alertType = payload?.alertType;
      state.message = payload?.message;
      state.open=payload?.open;
    },
    clearAlertState:(state)=>state=initialState
  },
})

export const { setAlert,clearAlertState } = alertSlice.actions
export default alertSlice.reducer
export const getAlertInfo=(state:any)=>state.alert;